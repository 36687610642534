<template>
  <div>
      <h3>Aus ufs Mau</h3>
      <div class="input-section">
          <div class="player-list">
              <div v-for="(name, index) in playerNames" :key="name">
                  <div class="player-item">
                      <p>{{ name }}:</p>
                      <div class="input-wrapper">
                          <input
                              class="value-input-field"
                              @input="updatePlayerBalance(name, $event.target.value)"
                              @keydown.enter.prevent="focusNext(index)"
                              type="number"
                              step="0.5"
                              max="17.50"
                              :ref="'playerInput' + index"
                              :data-name="name"
                          >
                      </div>
                      <p>CHF</p>
                  </div>
              </div>
          </div>
          <p v-if="showCheckAgainMessage">Da stimmt no öpis nid!</p>
          <button @click="submitAllRound" class="next-round-button">Nächsti Rundi</button>
      </div>
  </div>
  <div class="separator-container">
      <hr class="separator">
  </div>
  <ScoreTable :playerNames="playerNames" :roundsToShow="['heartRound', 'queenRound', 'snuesuRound','allRound']" />
</template>

<script>
import { inject, ref } from 'vue';
import ScoreTable from './ScoreTable.vue';

export default {
  components: {
      ScoreTable
  },
  props: ['playerNames'],
  setup() {
      const balanceStore = inject('balanceStore');
      const inputRefs = ref({});
      return { balanceStore, inputRefs };
  },
  data() {
      return {
          showCheckAgainMessage: false // Flag to control visibility of "Check again" message
      };
  },
  methods: {
      updatePlayerBalance(name, value) {
          const amount = Number(value);
          this.balanceStore.updateBalance(name, amount, 'allRound');
          this.validateallsum(); // Call validateallsum() after updating the balance
      },
      getBalance(name) {
          return this.balanceStore.getTotalRoundBalance(name, 'allRound');
      },
      validateallsum() {
          let allsum = 0;
          this.playerNames.forEach(name => {
              let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
              if (input) {
                  allsum += Number(input.value || 0);
              }
          });

          if (allsum > 17.50) {
              console.log("allsum exceeds 17.50");
              this.playerNames.forEach(name => {
                  let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
                  if (input) {
                      input.classList.add('error'); // Apply error class
                  }
              });
              this.showCheckAgainMessage = true;
          } else {
              this.playerNames.forEach(name => {
                  let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
                  if (input) {
                      input.classList.remove('error'); // Remove error class
                  }
              });
              this.showCheckAgainMessage = allsum !== 17.50 && this.allInputsFilled();
          }
      },
      allInputsFilled() {
          return this.playerNames.every(name => {
              let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
              return input && input.value;
          });
      },
      focusNext(index) {
          if (index + 1 < this.playerNames.length) {
              this.$nextTick(() => {
                  this.$refs[`playerInput${index + 1}`][0].focus();
              });
          }
      },
      submitAllRound() {
          let allsum = 0;
          this.playerNames.forEach(name => {
              allsum += this.getBalance(name, 'allRound');
          });

          if (allsum === 17.50) {
              this.$emit('nextRound');
          } else {
              this.showCheckAgainMessage = true;
          }
      }
  }
}
</script>

<style scoped>
</style>