import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './css/styles.css';
import { createVuetify } from 'vuetify'; // Import createVuetify from Vuetify
import 'vuetify/dist/vuetify.min.css';
import { balanceStore } from './balanceStore'; // Adjust the import path as needed

const app = createApp(App);

app.use(router);
app.provide('balanceStore', balanceStore); // Provide the balanceStore globally

app.mount('#app');
