<template>
  <div>
    <ExplanationPage msg="🃏 Egglerä 🃏" v-if="currentStep === 0" />
    <SelectPlayerCount @update-players="setPlayers" v-if="currentStep === 0" />
    <InstructionsText v-if="currentStep === 0"/>
    <PlayerNames :numberOfPlayers="numberOfPlayers" @namesSubmitted="handleNamesSubmitted" v-if="currentStep === 1" />
    <HeartRound :playerNames="playerNames" @nextRound="handleNextRound" v-if="currentStep === 2" />
    <QueenRound :playerNames="playerNames" @nextRound="handleNextRound" v-if="currentStep === 3" />
    <SnuesuRound :playerNames="playerNames" @nextRound="handleNextRound" v-if="currentStep === 4" />
    <AllRound :playerNames="playerNames" @nextRound="handleNextRound"  v-if="currentStep === 5" />
    <FinalRound :playerNames="playerNames" @positionsSubmitted="handlePositionsSubmitted" v-if="currentStep === 6" />
    <FinalSettlement :playerNames="playerNames" v-if="currentStep === 7" @nextRound="handleNextRound" />
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import { useRouter } from 'vue-router';
import { balanceStore } from '@/balanceStore';

import ExplanationPage from '@/components/HomePageWelcome.vue';
import SelectPlayerCount from '@/components/SelectPlayerCount.vue';
import PlayerNames from '@/components/PlayerNames.vue';
import HeartRound from '@/components/HeartRound.vue';
import QueenRound from '@/components/QueenRound.vue';
import SnuesuRound from '@/components/SnuesuRound.vue';
import AllRound from '@/components/AllRound.vue';
import FinalRound from '@/components/FinalRound.vue';
import FinalSettlement from '@/components/FinalSettlement.vue';
import InstructionsText from '@/components/InstructionsText.vue';

export default {
  name: 'HomePage',
  components: {
    ExplanationPage,
    SelectPlayerCount,
    InstructionsText,
    PlayerNames,
    HeartRound,
    QueenRound,
    SnuesuRound,
    AllRound,
    FinalRound,
    FinalSettlement
  },
  data() {
    return {
      numberOfPlayers: 0,
      playerNames: [],
      currentStep: 0,
    };
  },
  setup() {
    const router = useRouter();
    provide('balanceStore', balanceStore);
    return { router };
  },
  methods: {
    setPlayers(number) {
      this.numberOfPlayers = number;
      this.currentStep++;
    },
    handleNamesSubmitted(names) {
      this.playerNames = names;
      this.currentStep++;
      balanceStore.initializeBalances(names);
    },
    handlePositionsSubmitted(positions) {
      this.currentStep++;
    },
    handleNextRound() {
      this.currentStep++;
      if (this.currentStep === 8) {
        this.router.push({ name: 'HomePage' });
      }
    }
  }
};
</script>
