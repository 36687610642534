<template>
  <div>
    <h3>Ä Rundi Härze</h3>
    <div class="input-section">
      <div class="player-list">
        <div v-for="(name, index) in playerNames" :key="name">
          <div class="player-item" v-chf-prefix>
            <p>{{name}}:</p>
            <div class="input-wrapper">
              <input
                class="value-input-field"
                @input="updatePlayerBalance(name, $event.target.value)"
                @keydown.enter.prevent="focusNext(index)"
                type="number"
                step="0.5"
                max="4.50"
                :data-name="name"
                :ref="'playerInput' + index"
              >
            </div>
            <p>CHF</p>
          </div>
        </div>
        <p v-if="showCheckAgainMessage">Da stimmt no öpis nid!</p>
        <button @click="submitHeartRound" class="next-round-button">Nächsti Rundi</button>
      </div>
    </div>
    <div class="separator-container">
      <hr class="separator">
    </div>
    <ScoreTable :playerNames="playerNames" :roundsToShow="['heartRound']" />
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';
import ScoreTable from './ScoreTable.vue';
import vTooltip from '../v-tooltip.js';

export default {
  components: {
    ScoreTable
  },
  directives: {
    tooltip: vTooltip
  },
  props: ['playerNames'],
  setup() {
    const balanceStore = inject('balanceStore');
    const inputRefs = ref({});
    return { balanceStore, inputRefs };
  },
  data() {
    return {
      showCheckAgainMessage: false
    };
  },
  methods: {
    updatePlayerBalance(name, value) {
      const amount = Number(value);
      this.balanceStore.updateBalance(name, amount, 'heartRound');
      this.validateheartsum();
    },
    getBalance(name) {
      return this.balanceStore.getTotalRoundBalance(name, 'heartRound');
    },
    validateheartsum() {
      let heartsum = 0;
      this.playerNames.forEach(name => {
        let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
        if (input) {
          heartsum += Number(input.value || 0);
        }
      });
      if (heartsum > 4.50) {
        console.log("heartsum exceeds 4.50");
        this.playerNames.forEach(name => {
          let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
          if (input) {
            input.classList.add('error');
          }
        });
        this.showCheckAgainMessage = true;
      } else {
        this.playerNames.forEach(name => {
          let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
          if (input) {
            input.classList.remove('error');
          }
        });
        this.showCheckAgainMessage = heartsum !== 4.50 && this.allInputsFilled();
      }
    },
    allInputsFilled() {
      return this.playerNames.every(name => {
        let input = this.$refs[`playerInput${this.playerNames.indexOf(name)}`][0];
        return input && input.value;
      });
    },
    focusNext(index) {
      if (index + 1 < this.playerNames.length) {
        this.$nextTick(() => {
          this.$refs[`playerInput${index + 1}`][0].focus();
        });
      }
    },
    submitHeartRound() {
      let heartsum = 0;
      this.playerNames.forEach(name => {
        heartsum += this.balanceStore.getTotalRoundBalance(name, 'heartRound');
      });

      if (heartsum === 4.50) {
        this.$emit('nextRound');
      } else {
        this.showCheckAgainMessage = true;
      }
    }
  }
}
</script>

<style scoped>
.value-input-field {
  padding-left: 30px; /* Adjust padding to make room for the prefix */
}
</style>