import { reactive } from 'vue';

export const balanceStore = reactive({
  balances: {
    heartRound: {},
    queenRound: {},
    snuesuRound: {},
    allRound: {},
    elferausRound: {},
  },
  totalBalance: {},
  playerNames: [],
  initializeBalances(playerNames) {
    console.log('Initializing balances...');
    this.playerNames = playerNames.slice();
    this.playerNames.forEach(name => {
      this.balances.heartRound[name] = 0;
      this.balances.queenRound[name] = 0;
      this.balances.snuesuRound[name] = 0;
      this.balances.allRound[name] = 0;
      this.balances.elferausRound[name] = 0;
      this.totalBalance[name] = 0;
    });
    console.log('Balances initialized:', this.balances);
    console.log('Total balances initialized:', this.totalBalance);
  },
  updateBalance(playerName, amount, roundName) {
    console.log(`Updating balance for ${playerName} in ${roundName} with amount ${amount}`);
    if (this.balances[roundName][playerName] !== undefined) {
      const previousBalance = this.balances[roundName][playerName];
      this.balances[roundName][playerName] = amount;
      this.totalBalance[playerName] += amount - previousBalance;
      console.log(`Updated ${roundName} balance for ${playerName}: ${this.balances[roundName][playerName]}`);
      console.log(`Updated total balance for ${playerName}: ${this.totalBalance[playerName]}`);
    } else {
      console.error(`Player ${playerName} not found in ${roundName}`);
    }
  },
  updateElferausBalance(playerPositions) {
    console.log('Updating Elferaus balance with positions:', playerPositions);
  
    // Verify the positions
    if (!Array.isArray(playerPositions) || playerPositions.length !== this.playerNames.length) {
      console.error('Invalid player positions:', playerPositions);
      return;
    }
  
    // Reset only the elferausRound balances, not the total balances
    this.playerNames.forEach(name => {
      this.totalBalance[name] -= this.balances.elferausRound[name] || 0;
      this.balances.elferausRound[name] = 0;
    });
  
    // Update the balances based on the positions
    playerPositions.forEach((position, index) => {
      const playerName = this.playerNames[position - 1];
      const balanceAdjustment = -(this.playerNames.length - index);
      this.balances.elferausRound[playerName] = balanceAdjustment;
      this.totalBalance[playerName] += balanceAdjustment;
      console.log(`Updated Elferaus balance for ${playerName}: ${this.balances.elferausRound[playerName]}`);
      console.log(`Updated total balance for ${playerName}: ${this.totalBalance[playerName]}`);
    });
  },  
  getTotalRoundBalance(playerName, roundName) {
    return this.balances[roundName][playerName];
  },
  getTotalBalance(playerName) {
    return this.totalBalance[playerName];
  },
});
