<template>
    <div class="score-table">
        <h3>Zwüschestand</h3>
      <table>
        <thead>
          <tr>
            <th>Spieler</th>
            <th v-for="round in roundsToShow" :key="round">{{ roundNames[round] }}</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="name in playerNames" :key="name">
            <td>{{ name }}</td>
            <td v-for="round in roundsToShow" :key="round">{{ getRoundBalance(name, round) }}</td>
            <td>{{ getTotalBalance(name) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      playerNames: Array,
      roundsToShow: Array
    },
    inject: ['balanceStore'],
    computed: {
      roundNames() {
        return {
          heartRound: 'Härz',
          queenRound: 'Dame',
          snuesuRound: 'Snüsu',
          allRound: 'Aues',
          elferausRound: 'Elferaus',
        };
      }
    },
    methods: {
      getRoundBalance(name, round) {
        return this.balanceStore.getTotalRoundBalance(name, round);
      },
      getTotalBalance(name) {
        return this.balanceStore.getTotalBalance(name);
      }
    }
  }
  </script>
  
  <style scoped>
  .score-table table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
  }
  
  .score-table th,
  .score-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .score-table th {
    background-color: #f2f2f2;
  }
  </style>
  