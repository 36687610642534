<template>
    <div class="welcome">
      <h1>{{ msg }}</h1>
      <p>Diä Sitä hiuft dr bir Abrechnig bim Egglerä!<br/>
        Viu Spass & viu Glück!
      </p>
      <hr>
    </div>
</template>
<script>
  export default {
    name: "HomePageWelcome",
    props: {
      msg: String
    }
  };
</script>

<style scoped>
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 3px 3px;
  }
  hr {
  border: none;
  height: 0.5px;
  width: 40%;
  background: #333;
  margin: 40px auto;
}
</style>