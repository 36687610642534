<template>
    <div class="player-count">
      <h3>Wiviu spielä mit?</h3>
    </div>
    <div>
      <button
        v-for="number in 6"
        :key="number"
        role="link"
        @click="handleClick(number)"
        class="number-button"
        :style="buttonStyle(number)">
        {{ number + 2}}
      </button>
    </div>
  </template>
  
  <script>
  export default {
    emits: ['updatePlayers'], // Declare custom event
    data() {
      return {
        activeButton: null,
      };
    },
    methods: {
      handleClick(number) {
        this.activeButton = number;
        this.$emit('updatePlayers', number + 2);
      },
      buttonStyle(number) {
        return {
          backgroundColor: this.activeButton === number ? 'grey' : 'black',
          color: this.activeButton === number ? 'black' : 'white',
        };
      },
    },
  };
  </script>
  