<template>
  <hr>
  <h3>Zur Info:</h3>
  <li>Du chasch gd direkt Frankebeträg igä</li>
  <li>Bim Snüsu, muesch nume dr Spieler aklicke</li>
  <li>U bim Elferaus, chasch gd d Reihefoug igäh</li>
  <hr>
</template>

<script>
</script>

<style scoped>
  li {
    margin: 3px 3px;
  }
  hr {
  border: none;
  height: 0.5px;
  width: 40%;
  background: #333;
  margin: 40px auto;
}
</style>