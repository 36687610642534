export default {
    mounted(el, binding) {
      const tooltip = document.createElement('div');
      tooltip.classList.add('tooltip');
      tooltip.textContent = binding.value;
      tooltip.style.display = 'none';
      tooltip.style.position = 'absolute';
      tooltip.style.backgroundColor = '#333';
      tooltip.style.color = '#fff';
      tooltip.style.padding = '5px';
      tooltip.style.borderRadius = '3px';
      tooltip.style.zIndex = '1000';
      el.style.position = 'relative';
      el.appendChild(tooltip);
  
      const toggleTooltip = () => {
        tooltip.style.display = tooltip.style.display === 'none' ? 'block' : 'none';
      };
  
      el.addEventListener('click', toggleTooltip);
  
      el.addEventListener('blur', () => {
        tooltip.style.display = 'none';
      }, true);
  
      el.tooltipElement = tooltip;
    },
    unmounted(el) {
      const tooltip = el.tooltipElement;
      if (tooltip) {
        el.removeChild(tooltip);
      }
    },
  };
  