<template>
  <div class="player-names">
    <h3>Wär spiut aues mit?</h3>
    <div v-for="(n, index) in numberOfPlayers" :key="n">
      <input 
        type="text" 
        v-model="playerNames[n-1]" 
        class="player-input-field" 
        :placeholder="'Spieler ' + n" 
        :ref="'playerInput' + index"
        @keydown.enter.prevent="focusNext(index)"
      >
    </div>
    <div>
      <button @click="submitNames" class="lets-go-button">Legemer los</button>
      <p v-if="buttonClicked">Es spielä: {{ namesString }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberOfPlayers: Number,
  },
  data() {
    return {
      playerNames: Array(this.numberOfPlayers).fill(''),
      buttonClicked: false
    };
  },
  methods: {
    submitNames() {
      this.namesString = this.playerNames
        .filter(name => name)
        .join(', ');
      this.$emit('updatePlayerNames', this.playerNames);
      this.buttonClicked = true;
      this.$emit('namesSubmitted', this.playerNames);
    },
    focusNext(index) {
      if (index + 1 < this.numberOfPlayers) {
        this.$nextTick(() => {
          this.$refs[`playerInput${index + 1}`][0].focus();
        });
      }
    }
  },
};
</script>

<style scoped>
</style>