<template>
  <div class="input-section">
    <div>
      <h3>Wär nimmt Snüsu?</h3>
      <div class="player-list">
        <div
          v-for="name in playerNames"
          :key="name"
          :class="['player-item', { 'selected': selectedPlayer === name }]"
          @click="selectPlayer(name)"
        >
          {{ name }}
        </div>
      </div>
      <p v-if="showCheckAgainMessage">Bitte einen Spieler auswählen!</p>
      <button @click="submitSnuesuRound" class="next-round-button" :disabled="!selectedPlayer">
        Nächsti Rundi
      </button>
    </div>
  </div>
  <div class="separator-container">
      <hr class="separator">
  </div>
  <ScoreTable :playerNames="playerNames" :roundsToShow="['heartRound', 'queenRound', 'snuesuRound']" />
</template>

<script>
import { inject, watch } from 'vue';
import ScoreTable from './ScoreTable.vue';

export default {
  components: {
    ScoreTable
  },
  props: ['playerNames'],
  setup() {
    const balanceStore = inject('balanceStore');
    return { balanceStore };
  },
  data() {
    return {
      selectedPlayer: null,
      showCheckAgainMessage: false
    }
  },
  watch: {
    selectedPlayer(newPlayer) {
      if (newPlayer) {
        this.balanceStore.updateBalance(newPlayer, 5, 'snuesuRound'); // Update balance for snuesuRound
      }
    }
  },
  methods: {
    selectPlayer(name) {
      if (this.selectedPlayer) {
        this.balanceStore.updateBalance(this.selectedPlayer, 0, 'snuesuRound'); // Reset the previous player's balance
      }
      this.selectedPlayer = name;
      this.showCheckAgainMessage = false; // Reset error message when a player is selected
    },
    submitSnuesuRound() {
      if (this.selectedPlayer) {
        this.$emit('nextRound', this.selectedPlayer);
      } else {
        this.showCheckAgainMessage = true; // Show error message if no player is selected
      }
    }
  }
}
</script>

<style scoped>
.player-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}

.player-item {
  background-color: #f1f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid lightgrey; /* Add a border to create a frame */
  transition: background-color 0.3s, border-color 0.3s; /* Add transition for smooth color change */
}

.player-item.selected {
  background-color: red; /* Change background color to red when selected */
  color: white;
  border-color: red; /* Change border color to red when selected */
}

.next-round-button {
  margin-top: 20px;
}

p {
  color: red;
  font-weight: bold;
}
</style>
