<template>
  <div>
    <div class="settlement-round">
      <h3>💰 Iz wird abgrechnet 💰</h3>
      <table class="balance-table">
        <thead>
          <tr>
            <th>🃏</th>
            <th>Härz</th>
            <th>Dame</th>
            <th>Snüsu</th>
            <th>Aues</th>
            <th>Elferaus</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="name in playerNames" :key="name">
            <td>{{ name }}</td>
            <td>{{ getRoundBalance(name, 'heartRound') }}</td>
            <td>{{ getRoundBalance(name, 'queenRound') }}</td>
            <td>{{ getRoundBalance(name, 'snuesuRound') }}</td>
            <td>{{ getRoundBalance(name, 'allRound') }}</td>
            <td>{{ getRoundBalance(name, 'elferausRound') }}</td>
            <td>{{ getTotalBalance(name) }}</td>
          </tr>
        </tbody>
      </table>
      <div class="separator-container">
        <hr class="separator">
      </div>
      <div class="settlement-container">
        <h3>Bitte fougendi Schulde begliche:</h3>
        <div v-for="(settlement, index) in settlements" :key="index">
          <p>{{ settlement.payer }} --> {{ settlement.payee }}: CHF {{ settlement.amount }}.-</p>
        </div>
      </div>
      <div class="separator-container">
        <hr class="separator">
      </div>

      <button @click="refreshPage" class="next-round-button">Nomau spielä</button>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';

export default {
  props: ['playerNames'],
  setup() {
    const balanceStore = inject('balanceStore');
    return { balanceStore };
  },
  computed: {
    settlements() {
      const sortedBalances = this.playerNames.map(name => ({
        name,
        balance: this.balanceStore.getTotalBalance(name)
      })).sort((a, b) => a.balance - b.balance); // Sort by ascending balance

      const settlements = [];
      let i = 0;
      let j = sortedBalances.length - 1;

      while (i < j) {
        const payer = sortedBalances[j].name;
        const payee = sortedBalances[i].name;
        const amount = Math.abs(sortedBalances[j].balance - sortedBalances[i].balance);

        settlements.push({ payer, payee, amount });

        i++;
        j--;
      }

      return settlements;
    }
  },
  methods: {
    getRoundBalance(name, roundName) {
      return this.balanceStore.getTotalRoundBalance(name, roundName);
    },
    getTotalBalance(name) {
      return this.balanceStore.getTotalBalance(name);
    },
    resetBalances() {
      this.playerNames.forEach(name => {
        this.balanceStore.balances.heartRound[name] = 0;
        this.balanceStore.balances.queenRound[name] = 0;
        this.balanceStore.balances.snuesuRound[name] = 0;
        this.balanceStore.balances.allRound[name] = 0;
        this.balanceStore.balances.elferausRound[name] = 0;
        this.balanceStore.totalBalance[name] = 0;
      });
    },
    refreshPage() {
      this.resetBalances();
      window.location.reload(); // Refresh the page
    }
  }
}
</script>

<style scoped>
.balance-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
}

.balance-table th,
.balance-table td {
  padding: 6px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.balance-table th {
  background-color: #f2f2f2;
}

.settlement-container {
  margin-top: 20px;
  text-align: center;
}

.next-round-button {
  margin-top: 20px;
}
</style>
