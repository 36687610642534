<template>
  <div>
    <h3>U no äs Elferaus:</h3>
    <div class="input-section">
      <div class="player-list">
        <div v-for="(name, index) in playerNames" :key="index">
          <div class="player-item">
            <p>{{ name }}:</p>
            <input
              class="value-input-field"
              v-model="positions[index]"
              :data-name="name"
              type="number"
              min="1"
              :max="playerNames.length"
              @input="validatePositions"
              @keydown.enter.prevent="focusNext(index)"
              :ref="'playerInput' + index"
            >
          </div>
        </div>
        <p v-if="showCheckAgainMessage">Da stimmt no öpis nid!</p>
        <button @click="nextRound" class="next-round-button" :disabled="!positionsValid">Nächsti Rundi</button>
      </div>
    </div>
    <div class="separator-container">
      <hr class="separator">
    </div>
    <ScoreTable :playerNames="playerNames" :roundsToShow="['heartRound', 'queenRound', 'snuesuRound', 'allRound', 'elferausRound']" />
  </div>
</template>

<script>
import { inject } from 'vue';
import ScoreTable from './ScoreTable.vue';

export default {
  components: {
    ScoreTable
  },
  props: {
    playerNames: {
      type: Array,
      required: true
    }
  },
  setup(props) {
    const balanceStore = inject('balanceStore');
    return { balanceStore };
  },
  data() {
    return {
      positions: Array(this.playerNames.length).fill(''),
      showCheckAgainMessage: false
    };
  },
  computed: {
    positionsValid() {
      const uniquePositions = new Set(this.positions);
      const allFilled = this.positions.every(pos => pos > 0 && pos <= this.playerNames.length);
      return uniquePositions.size === this.playerNames.length && allFilled;
    }
  },
  methods: {
    validatePositions() {
      this.showCheckAgainMessage = !this.positionsValid;
    },
    focusNext(index) {
      if (index + 1 < this.playerNames.length) {
        this.$nextTick(() => {
          this.$refs[`playerInput${index + 1}`][0].focus();
        });
      }
    },
    nextRound() {
      if (this.positionsValid) {
        this.balanceStore.updateElferausBalance(this.positions.map(Number));
        this.$emit('positionsSubmitted', this.positions);
        this.showCheckAgainMessage = false;
      } else {
        this.showCheckAgainMessage = true;
      }
    }
  }
}
</script>

<style scoped>
</style>