<template>
  <div id="app">
    <div class="nav-bar">
      <div id="nav">
        <router-link to="/">Abrechnig</router-link>&nbsp;
        <router-link to="/hiuf">Hiuf</router-link>
      </div>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>