import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/hiuf',
    name: 'HiufeSite',
    component: () => import('./views/AboutPage.vue')
  },
  {
    path: '/player-names',
    name: 'PlayerNames',
    component: () => import('@/components/PlayerNames.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
